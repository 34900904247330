import { useDispatch } from "react-redux";
import uuid from "react-uuid";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


import { addProductToBasket, delProductFromBasket, updateOrder } from "../store/reducers/reducers";

function CardForProducts({
    id, pic, picDesc, title, desc, num, reff, comm, price }) {  
        
    const basket = useSelector( state => state.order.basketProducts);
    const count = basket.filter((i)=>i.id===id).length;

    function searchItemIndexById(id){
        let item=basket.find(i=>i.id===id);
        return item.index;
    }

    const dispatch = useDispatch();
    const addProduct = (event) => {
        event.preventDefault();
        const item = { 
            key: uuid(),
            index: uuid(),
            id: id,
            pic: pic,
            title: title,
            desc: desc,
            num: num, 
            price: price,   
            reff: reff,     
            comm: comm,  
        };
        if (count<item.num) {
            dispatch(addProductToBasket(item));
            dispatch(updateOrder(item));
        }

    }

    const removeProduct = (event) => {
        event.preventDefault();
        dispatch(delProductFromBasket(searchItemIndexById(id)));
        dispatch(updateOrder())
        
    }

    return(
        <Link to={`/details/${id}`} className="card">
            <div className="card__inner">
                
                <div className="card__picture">
                    <img src={pic} alt={picDesc} />
                </div>
                
                <div>
                    <h2 className="card__title">
                        {title}
                    </h2>
                    <p className="card__desc">
                        {desc}
                    </p>
                </div>
            </div>
            <div className="card__footer">
                <div>
                    {/* <span className="card__price">
                        { {num.toLocaleString()} ₽ }
                    </span> */}
                    <span className="card__weight">
                        Цена: <b>{price} ₽.</b><br></br>
                        Кол-во: {num} шт.
                        
                    </span>
                </div>
                <div className="card__order-section">
                    <div className="card__add" >
                        <svg 
                            onClick={ removeProduct }
                            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">                                
                                <circle cx="15" cy="15" r="14.5" />
                                <path d="M20.3569 14.8214L9.28551 14.8213" strokeWidth="2" strokeLinecap="round"/>                                                                
                        </svg>
                    </div>
                    <div className="card__count">{count}</div>
                    <div className="card__add" >
                        <svg 
                            onClick={ addProduct }
                            width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="15" cy="15" r="14.5" />
                                {/* <circle cx="15" cy="15" r="15" fill="#D58C51"/> */}
                                <path d="M15 9.28564V20.3571" strokeWidth="2" strokeLinecap="round"/>
                                <path d="M20.3569 14.8214L9.28551 14.8213" strokeWidth="2" strokeLinecap="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </Link>

    );    
}
export default CardForProducts;