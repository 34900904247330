import CardForBasket from "../components/CardForBasket";
import { order, updateOrder } from "./../store/reducers/reducers"

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../components/Header.jsx";

function Basket() {

    const basket = useSelector( state => state.order.basketProducts);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    
    const pricesSetted = () => {   // можно лучше
        let r = false;
        basket.forEach( (i) => (i.price<=0 || i.price===undefined)?r=false:r=true )                
        return r;
    }
    
    const reducedBasket = Object.values(basket.reduce(
        (acc, val) => {
          acc[val.id] = Object.assign(acc[val.id] ?? {}, val);
          return acc;
        },
        {}
    ));
    
    const copyTextToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          console.log('Текст успешно скопирован в буфер обмена!');
        } catch (err) {
          console.error('Ошибка:', err);
        }
    };
    
    const orderBasket = () => {
        // alert('Заказ оформлен!');

        if ( !pricesSetted() ) {
            alert('Необходимо задать цену!');            
        } else {

            let body = Array.from(new Set([...basket]))
            .sort(function (a,b) {
                    if(a.id>b.id) return 1;
                    if(a.id<b.id) return -1;
                    return 0;
            });

            let len = body.length;      //  переписать ????
            let id = '';                //  когда архитектура изменится
            let str = 0;
            
            const count = (i) => { return body.filter((i)=>i.id===id).length }
            
            let filteredBasket = '';

            for (var i = 0; i<len; i++) {
                if (body[i].id !== id) {
                    str += 1;
                    id = body[i].id;
                    filteredBasket += str + ". " +
                                        body[i].title + " " +
                                        body[i].price + "р. " + 
                                        count(i) +'шт.\n';
                }
            }
        
            console.log(filteredBasket);
            copyTextToClipboard(filteredBasket);

            dispatch(order());
            dispatch(updateOrder());


            navigate('/products');

            
            var mail = document.createElement("a");
            let mailBody = "Прошу рассмотреть возможность заказа устройств по следующим ценам:\n";
            mail.href = `mailto:mail@example.org?subject=Заказ Sepam с сайта relesepam.ru
                                &body=` + encodeURIComponent(mailBody + filteredBasket);
            mail.click();

            return filteredBasket;
        }
    }
    
    return(
        <div className="basket">

            <Header backButtonVis={true} basketButtonVis={false} />

            <main className="basket__main basket__inner-container">            
            {reducedBasket.map(item=>{
                return (
                    <CardForBasket
                        key={item.key}
                        id={item.id}
                        index={item.index}
                        pic={item.pic}
                        title={item.title}
                        desc={item.desc}
                        price={item.price} 
                        reff={item.reff}   
                        comm={item.comm}
                    />
                )}
            )}
            </main>
            <footer className="basket__footer">
                <div className="basket__footer-container">                  
                    <button                         
                        id="button1"
                        className="basket__footer-button button"
                        onClick={orderBasket}
                        // disabled={!pricesSetted()}
                    >Оформить заказ</button>
                </div>              
                
            </footer>
        </div>
    )
}

export default Basket;
