// import { Navigate } from "react-router-dom";
// import { useAuth } from "../hooks/useAuth";

// function useAuth() {
//   // let usr = localStorage.getItem("user");
//   // console.log(usr);
//   return JSON.parse(localStorage.getItem("authUser")) 
//     ? 
//       true
//     :
//       false;
// }


export const ProtectedRoute = ({ children }) => {
  // const user = useAuth();
  // if (!user) {
  //   // user is not authenticated
  //   return <Navigate to="/login" />;
  // }
  return children;
};