// import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import uuid from "react-uuid";
import { addProductToBasket, updateOrder } from "../store/reducers/reducers";
// import { useState } from "react";

import { pricelist } from "../pricelist.js";
import { Header } from "../components/Header.jsx";


function Details() {

    // const numOfProducts = useSelector( state => state.order.numOfProducts);
    // const sum = useSelector( state => state.order.sum);
    const params = useParams();
    const prodId = Number(params.id);

    const filteredBasket=pricelist.filter( (item) => item.id===prodId);
    const currNumberOfItems = useSelector( state => state.order.basketProducts.filter( (i)=>i.id===prodId ) ).length;
    // const currItem = useSelector( state => state.order.basketProducts.filter( (i)=>i.id===prodId ) );

    const details = filteredBasket[0];
    // const itemPrice = (currNumberOfItems>0)?currItem[0].price:null;// можно ли так делать????

    // const [actualPrice, setActualPrice] = useState(itemPrice);
    
    // const count = filteredBasket.length;

    const dispatch = useDispatch();

    function addProduct() {

        // let addPrice = Number(document.getElementById('newPrice').value);
                
        const item = { 
            key: uuid(),
            index: uuid(),
            id: details.id,
            pic: details.pic,
            title: details.title,
            num: details.num,              
            price: details.price,

            desc: details.desc,
            
            reff: details.reff,     
            comm: details.comm,  
        };        

        dispatch(addProductToBasket(item));
        dispatch(updateOrder(item));
        
    }

    // function updateItemPrice() {

    //     let curPrice = Number(document.getElementById('newPrice').value);
                
    //     const item = { 
    //         key: uuid(),
    //         index: uuid(),
    //         id: details.id,
    //         pic: details.pic,
    //         title: details.title,
    //         num: details.num,              
    //         price: curPrice,
    //     };
    //     dispatch(updatePrice(item));        
    // }

    return (
        <div className="details">           

                
            <Header backButtonVis={true}/>

            <main className="details__main">            
                <div className="details__img">
                    <img src={details.pic} alt={details.picDesc} 
                    />
                </div>
                <div className="details__inner">
                    <h2 className="details__title">
                        {details.title} {details.desc}
                    </h2>
                    {/* <p className="details__desc">
                        
                    </p> */}
                    <p className="details__count">
                        <span className="details__price">
                                В корзине {currNumberOfItems}
                        </span>
                        <span className="details__weight">
                            &nbsp;из {details.num} шт.
                        </span>
                    </p>

                    <p className="details__price">
                        Цена:<br></br>{details.price} ₽
                        
                        {/* <input className="details__price-input"
                            id="newPrice" type="number" step="1000" value={(actualPrice===null)?"":actualPrice}
                            onChange={e => setActualPrice(
                                Number(e.target.value>0?e.target.value:-e.target.value)
                            )}
                        />р. */}
                         
                    </p>
                    
                    <div className="details__buttons">                        
                        <button
                            className="button details__order-button"
                            onClick={addProduct}
                        >Добавить товар в корзину</button>

                        {/* <button
                            className="button details__order-button"
                            onClick={updateItemPrice}
                            hidden={(currNumberOfItems<=0)?true:false}
                        >Обновить цену</button> */}

                    </div>
                </div>
            </main>
        </div>
    )}

export default Details;