import { useSelector } from "react-redux";
import { Header } from "../components/Header";

import CardForProducts from "../components/CardForProducts";

function Products() {
    
    const pricelist = useSelector( state => state.order.products);
    
    return(        
        <div className="products-page">
            <Header backButton = {false}/>
            
            <main className="main">
                {pricelist.map(item=>{
                    return (
                        <CardForProducts
                            key={item.id}
                            id={item.id}
                            pic={item.pic}
                            picDesc={item.picDesc}
                            title={item.title}
                            desc={item.desc}
                            price={item.price}
                               
                            num={item.num}   
                            index={item.index}  
                            
                            reff={item.reff}
                            comm={item.comm}
                        />
                    )
                    }
                )}
            </main>
            <footer className="footer">
                <a href="https:\\relesepam.ru">www.relesepam.ru</a>
            </footer>
        </div>
    )
}

export default Products;