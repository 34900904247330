// вот что примерно будет отдавать бэк
export const pricelist = [
    {
        id:10,
        pic: '/images/S10A42EG.png',
        picDesc: "S10A42EG",
        title: `Sepam серии 10`,
        desc: "тип A42E",
        num: 1,
        price: 35000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `REL59828`,
        comm: `питание 220В универсальное`
    },
    {
        id:11,
        pic: '/images/S10A42EF.png',
        picDesc: "S10A42EF",
        title: `Sepam серии 10`,
        desc: "тип A42F",
        num: 1,
        price: 35000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `REL59829`,
        comm: `питание 220В пост. ток`
    },
    {
        id:12,
        pic: '/images/S1000UVM87R.png',
        picDesc: "S1000UVM87R",
        title: `Sepam серии 80`,
        desc: "тип M87",
        num: 1,
        price: 150000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `S1000UVM87R`,
        comm: `большой граф. дисплей`
    },
    {
        id:13,
        pic: '/images/S1000UVS82R.png',
        picDesc: "S1000UVS82R",
        title: `Sepam серии 80`,
        desc: "тип S82",
        num: 1,
        price: 130000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `S1000UVS82R`,
        comm: `большой граф. дисплей`
    },
    {
        id:14,
        pic: '/images/S1000MDS40R.png',
        picDesc: "S1000MDS40R",
        title: `Sepam серии 40`,
        desc: "тип S40",
        num: 1,
        price: 75000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `S1000MDS40R`,
        comm: `c дисплеем`
    },    
    {
        id:15,
        pic: '/images/S1000MXS41R.png',
        picDesc: "S1000MXS41R",
        title: `Sepam серии 40`,
        desc: "тип S41",
        num: 1,
        price: 75000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `S1000MDS41R`,
        comm: `без дисплея`
    },
    {
        id:16,
        pic: '/images/ACE850TP.png',
        picDesc: "ACE850TP",
        title: `Модуль связи для Sepam`,
        desc: "",
        num: 3,
        price: 40000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `ACE850TP`,
        comm: `МЭК-61850`
    },
    {
        id:17,
        pic: '/images/VAMP120.png',
        picDesc: "VAMP120",
        title: `Дуговая защита`,
        desc: "Vamp120",
        num: 1,
        price: 25000,
        details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
        reff: `Vamp120`,
        comm: `1 датчик дуги в комплекте`
    },
    // **************************************************************
    // {
    //     id:2,
    //     pic: '/images/2.png',
    //     picDesc: "S1000MDS40R",
    //     title: `Sepam серии 40`,
    //     desc: "тип S40",
    //     num: 4,
    //     price: 0,
    //     details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`,
    //     reff: `S1000MDS40R`,
    //     comm: `c дисплеем`
    // },
    // {
    //     id:3,
    //     pic: '/images/3.png',
    //     picDesc: "DSM303",
    //     title: "Ремонт дисплеев",
    //     desc: "Ремонт дисплеев",
    //     num: 1,
    //     price: 0,
    //     details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`
    // },    
    // {
    //     id:4,
    //     pic: '/images/2.png',
    //     picDesc: "Sepam 20",
    //     title: "Sepam 20",
    //     desc: "Sepam 20",
    //     num: 1,
    //     price: 0,
    //     details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`
    // },    
    // {
    //     id:5,        
    //     pic: '/images/2.png',
    //     picDesc: "Sepam 20",
    //     title: "Sepam 20",
    //     desc: "Sepam 20",
    //     num: 1,
    //     price: 0,
    //     details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`
    // },
    // {
    //     id:6,        
    //     pic: '/images/2.png',
    //     picDesc: "Sepam 20",
    //     title: "Sepam 20",
    //     desc: "Sepam 20",
    //     num: 1,
    //     price: 0,
    //     details: `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam labore harum laboriosam? Ut velit temporibus qui blanditiis, dolore earum commodi illo id veniam debitis! Tempore beatae minus temporibus accusantium non.`
    // },
   
];
