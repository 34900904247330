import React from 'react';
import ReactDOM from 'react-dom/client';
// import { Navigate } from 'react-router-dom';
import {
  Provider
} from 'react-redux';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import BigSpinner from 'react';

import {
  store
} from './store/store.js';
import Basket from './pages/Basket';
import Products from './pages/Products';
import Details from "./pages/Details";
import { Alert } from './components/Alert.jsx';


import './style/style.scss';
import { ProtectedRoute } from './components/protectedRoutes.jsx';

// const navigate = useNavigate();

const routes = createBrowserRouter( [
  {
    path: "/basket",
    element: 
      <ProtectedRoute>
        < Basket / >
      </ProtectedRoute>
  },
  {
    path: "/products",
    element: 
      <ProtectedRoute>
        < Products / >
      </ProtectedRoute>
  },
  {
    path: "/details/:id",
    element: 
      <ProtectedRoute>
        < Details / >
      </ProtectedRoute>
  },
  {
    path: "/",
    element: < Products / >
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <
  React.StrictMode >
    <Provider store = { store } >
      {/* <Alert /> */}
      < RouterProvider router = { routes } fallbackElement = { BigSpinner } />
    </Provider>
  </React.StrictMode>
);