import { createSlice } from "@reduxjs/toolkit";
import { pricelist } from "../../pricelist.js";

pricelist.forEach( (item) => {
    // item.count = 0
    item.index = 0;
    item.key = 0;
    //item.price = 0;
} );

const initialState = {
    products: pricelist,
    basketProducts: [],
    numOfProducts: 0,
}

export const orderSlice = createSlice({
    name: 'orderReducer',
    initialState,
    reducers: {
        addProductToBasket: (state, action) => {            
            const maxNumberOfItems = action.payload.num;
            const currNumberOfItems = state.basketProducts.filter((i)=>i.id===action.payload.id).length;
            
            // проходимся по продуктам с одинаковым id, цена у всех продуктов - последняя введенная
            state.basketProducts.forEach(
                (i)=> 
                 (i.id===action.payload.id)?i.price=action.payload.price:false)
            

            if (currNumberOfItems < maxNumberOfItems) {
                state.basketProducts.push(action.payload)
            // console.log('Событие при добавлении в корзину: ', action)
            }
        },
        delProductFromBasket: (state, action) => {
            state.basketProducts = state.basketProducts.filter((item) => {
                return item.index !== action.payload;                
            })
            // console.log('Событие при удалении из корзины: ', action.payload)
        },
        updateOrder: (state, action) => {           
            // state.sum = state.basketProducts.reduce((acc, tmp) => acc + tmp.price, 0);
            
            state.numOfProducts = state.basketProducts.length;
        },
        updatePrice: (state, action) => {                        
            // проходимся по продуктам с одинаковым id, цена у всех продуктов - последняя введенная
            state.basketProducts.forEach(
                (i)=> 
                 (i.id===action.payload.id)?i.price=action.payload.price:false)           

        },        
        
        order: (state) => {
            state.basketProducts.length = 0; // splice!
        }
    }
});
export const { addProductToBasket, delProductFromBasket, updateOrder, updatePrice, order} = orderSlice.actions;
export default orderSlice.reducer;